import React from "react";
import styles from "./AdiModuleSix.module.css";

export default function AdiModuleSix() {
  return (
    <>
      {" "}
      <div className={styles.AdiModuleOnecontainer}>
        <section className={styles.AdiModuleOneheader}>
          <h1>Observation in the COAST Method for Advanced Driving</h1>
          <div className={styles.AdiModuleOnevideo}>
            <p> INSERT VIDEO - CONCENTRATION</p>
          </div>
        </section>

        <section className={styles.AdiModuleOneobjectives}>
          <h2>Objective:</h2>
          <p>By the end of this lesson, you will:</p>
          <ul>
            <li>
              {" "}
              1. Understand the importance of Observation in advanced driving.{" "}
            </li>
            <li>
              {" "}
              2. Learn techniques to enhance your observation, including
              recognizing and using limit points.
            </li>
            <li>
              3. Participate in an activity to practice observation and limit
              point skills in real driving situations.
            </li>
          </ul>
        </section>

        <section className={styles.AdiModuleOneintroduction}>
          <h2>1. Introduction to Observation in Driving</h2>
          <p>
            Observation is the process of gathering and analysing visual
            information while driving. In the COAST method, observation is
            critical for identifying hazards, maintaining situational awareness,
            and making proactive decisions.
          </p>
          <p>
            Advanced driving requires drivers to go beyond passive observation
            by using techniques such as systematic scanning and assessing limit
            points—a key concept in safely navigating bends and curves.
          </p>
        </section>

        <section className={styles.AdiModuleOnechecks}>
          <h2>2. What is a Limit Point?</h2>
          <p>
            The limit point is the furthest point on the road ahead that you can
            see clearly. It is where the road surface appears to meet the
            horizon, often changing as you approach bends or hills. <br />
            Understanding and using the limit point helps you:{" "}
          </p>
          <div className={styles.AdiModuleOnecheckList}>
            <ul>
              <li>
                <strong>1. Judge Safe Speeds :</strong> If the limit point moves
                away from you, it’s safe to increase speed. <br />
                If it moves closer, reduce your speed to ensure control.
              </li>
              <li>
                <strong>2. Prepare :</strong> The limit point can reveal hidden
                dangers, such as sharp bends or stopped vehicles, as it changes.
              </li>

              <li>
                <strong>3. Navigate Bends Safely :</strong> By observing the
                limit point, you can adjust your speed and position before
                entering a curve.
              </li>
            </ul>
          </div>
        </section>
        <section className={styles.AdiModuleOneactivity}>
          <h2> Why is Observation Important? </h2>
          <div className={styles.AdiModuleOnechecklist}>
            <h3>1. Improves Hazard Awareness :</h3>
            <ul>
              <li>Spotting hazards early helps you avoid sudden reactions</li>
            </ul>
            <h3> 3. Ensures Smooth Driving :</h3>
            <ul>
              <li>
                Proper observation allows you to adapt smoothly to changes,
                maintaining control.
              </li>
            </ul>
            <h3>3. Demonstrates Professionalism : </h3>
            <ul>
              <li>
                Observing road conditions and limit points demonstrates the
                skill required of advanced drivers and instructors.
              </li>
            </ul>
          </div>
        </section>

        <section className={styles.AdiModuleOneactivity}>
          <h2>4. Techniques for Effective Observation</h2>
          <div className={styles.AdiModuleOnechecklist}>
            <h3>A. The Systematic Scan :</h3>
            <ul>
              <li>
                <strong>Far Ahead :</strong>
                Look far ahead to identify potential hazards up to your limit
                point
              </li>
              <li>
                <strong>Mid-Range :</strong>
                Monitor the road just ahead of your vehicle for immediate
                concerns.
              </li>
              <li>
                <strong>Close Range :</strong> Check for nearby issues like
                cyclists or pedestrians.{" "}
              </li>
            </ul>
            <h3>B. Observe Limit Points :</h3>
            <ul>
              <li>
                <strong>1. a Bend :</strong>
                Observe the limit point at the bend. Adjust your speed so you
                can stop safely within the distance you can see ahead.
              </li>
              <li>
                <strong>2. In the Bend : </strong>
                Keep your eyes on the limit point. If it moves further away, you
                can begin to accelerate. If it stays close, maintain reduced
                speed.
              </li>
              <li>
                <strong>3. After the Bend: </strong> Once the limit point opens
                fully, you can resume normal driving speed, keeping an eye out
                for new hazards.{" "}
              </li>
            </ul>
            <h3>C. Use the "Triangle of Vision :</h3>
            <ul>
              <li>
                <strong>Regularly shift focus between : </strong> <br />
                1. The road ahead. <br />
                2. Mirrors for awareness of traffic behind. <br />
                3. Side windows and blind spots for additional hazards.
              </li>
            </ul>
            <h3>D. Actively Look for Hazards :</h3>
            <ul>
              <li>
                <strong>Hazards include:</strong>
                <br></br>- Vehicles entering from side roads. <br></br>- Road
                signs and markings (e.g., "Sharp Bend Ahead"). <br></br>-
                Pedestrians, cyclists, and motorcyclists.
              </li>
            </ul>
            <h3>E. Avoid Fixed Vision :</h3>
            <ul>
              <li>
                <strong>Hazards include:</strong>
                <br></br>- - Don’t focus on one point too long. <br />-
                Constantly scan and adjust your vision to ensure full
                situational awareness.
              </li>
            </ul>
            <h3>F. Adapt to Road Conditions :</h3>
            <ul>
              <li>
                <strong>Weather :</strong>
                Adjust observation for reduced visibility. <br></br>
                <strong>Traffic :</strong>
                Monitor the flow of vehicles and anticipate sudden changes.
              </li>
            </ul>
          </div>
        </section>
        <section className={styles.AdiModuleOneactivity}>
          <h2>5. Activity: Observation and Limit Point Practice </h2>
          <div className={styles.AdiModuleOnechecklist}>
            <h3>Part A: Pre-Drive Awareness :</h3>
            <ul>
              <li>
                <strong>Before driving, answer these questions :</strong>
                <br />
                1. Have I adjusted my seat and mirrors for maximum visibility?{" "}
                <br />
                2. Do I know the weather and road conditions I’ll encounter?{" "}
                <br />
                3. Am I mentally prepared to scan for hazards and assess limit
                points?
              </li>
            </ul>
            <h3>Part B: On-the-Road Challenge :</h3>
            <ul>
              <li>
                <strong>1. Hazard Spotting Drill :</strong>
                - During a 10-minute drive: <br />
                - Identify and categorize hazards into: <br />
                - Far ahead (e.g., a junction, sharp bends). <br />
                - Mid-range (e.g., vehicles merging or stopping). <br />- Close
                range (e.g., cyclists or potholes).
              </li>
              <li>
                <strong>2. Limit Point Navigation :</strong>
                - On a stretch of road with bends: <br />
                - Observe the limit point as you approach a curve. <br />
                - Adjust your speed so you can stop safely within the visible
                distance. <br />
                - After exiting the curve, note how the limit point moved and
                whether your speed adjustments were effective. <br />
              </li>
            </ul>
          </div>
        </section>
      </div>
    </>
  );
}
