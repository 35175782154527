import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import styles from "./css/notfound.module.css";
import { removingCart } from "../redux/features/cartSlice";
import { useDispatch } from "react-redux";
import smartlearnerLogo from "../assets/images/smartlearnerLogo-removebg-preview.png";

const PaymentSuccess = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (localStorage.getItem("cart")) {
      localStorage.setItem("cart", JSON.stringify({}));
      dispatch(removingCart());
    }
  });
  return (
    <section className={styles.page405}>
      <div style={{ textAlign: "center", padding: "1rem" }}>
        <img id={styles.whiteLogo} src={smartlearnerLogo} alt="logo" />
      </div>

      <div className={styles.container}>
        <div className={styles.row}>
          <div className={`${styles.colSm} ${styles.colSm12}`}>
            <div
              className={`${styles.colSm} ${styles.colSm10} ${styles.colSmOffset1} ${styles.textCenter}`}>
              <div className={styles.paymetSucces}>
                <h1>Payment Success</h1>
              </div>
              <div className={styles.contentBox404}>
                <h2>Congratulations</h2>
                <p style={{ fontSize: "1rem" }}>
                  {" "}
                  Congratulations your payment has been successful. Please
                  contact us on +4402475092784 to get started
                </p>
              </div>
              <div className={styles.formFooter2222}>
                <Link type="button" to={"/home"}>
                  Proceed to Home Page
                </Link>
              </div>
              <div className={styles.formFooter3333}>
                <div className={styles.formFooter2222}>
                  <Link type="button" to="/adi-part-one">
                    PDI Part 1
                  </Link>
                </div>
                <div className={styles.formFooter2222}>
                  <Link type="button" to="/adi-part-2">
                    PDI Part 2
                  </Link>
                </div>
                <div className={styles.formFooter2222}>
                  <Link type="button" to="/adi-part-3">
                    PDI Part 3
                  </Link>
                </div>
              </div>
              <div className={styles.formFooter2222}>
                {/* <Link type="button" to="/Theory-Portal">
                  Theory Portal
                </Link> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PaymentSuccess;
