import React from "react";
import styles from "./AdiModuleSix.module.css";

export default function AdiModuleSeven() {
  return (
    <>
      {" "}
      <div className={styles.AdiModuleOnecontainer}>
        <section className={styles.AdiModuleOneheader}>
          <h1>Anticipation in the COAST Method for Advanced Driving</h1>
          <div className={styles.AdiModuleOnevideo}>
            <p> INSERT VIDEO ANTICIPATION</p>
          </div>
        </section>

        <section className={styles.AdiModuleOneobjectives}>
          <h2>Objective:</h2>
          <p>By the end of this lesson, you will:</p>
          <ul>
            <li>
              {" "}
              1. Understand the role of Anticipation in advanced driving and how
              it enhances safety.{" "}
            </li>
            <li>
              {" "}
              2. Learn practical strategies to anticipate potential hazards.
            </li>
            <li>
              3. Complete an activity to practice and develop your anticipation
              skills on the road.
            </li>
          </ul>
        </section>

        <section className={styles.AdiModuleOneintroduction}>
          <h2>1. Introduction to Anticipation in Driving</h2>
          <p>
            Anticipation is the ability to predict what might happen on the road
            based on your observations and driving experience. It is a proactive
            approach that helps drivers avoid hazards before they become
            problems. In advanced driving, anticipation is key to staying ahead
            of situations and maintaining smooth, controlled driving.
          </p>
          <p>
            For your ADI Part 2 exam, the examiner will assess your ability to
            anticipate and react to developing situations effectively and
            professionally.
          </p>
        </section>

        <section className={styles.AdiModuleOnechecks}>
          <h2>2. Why is Anticipation Important?</h2>

          <div className={styles.AdiModuleOnecheckList}>
            <ul>
              <li>
                <strong>1. Prevents Accidents :</strong> By predicting hazards
                early, you can take actions to avoid collisions or sudden stops.
              </li>
              <li>
                <strong>2. Ensures Smooth Driving :</strong> Anticipation allows
                you to plan your manoeuvres in advance, reducing the need for
                abrupt actions.
              </li>

              <li>
                <strong>3. Demonstrates Professionalism :</strong> Anticipating
                hazards shows the examiner you are in control and capable of
                teaching safe driving techniques
              </li>
            </ul>
          </div>
        </section>
        <section className={styles.AdiModuleOneactivity}>
          <h2> 3. Key Strategies for Anticipating Hazards </h2>
          <div className={styles.AdiModuleOnechecklist}>
            <h3>A. Look Ahead and Around :</h3>
            <ul>
              <li>
                Use commentary drive to talk through any potential hazards as
                far as you can see.
              </li>
              <li>
                Check mirrors frequently to stay aware of what is happening
                behind and to the sides.{" "}
              </li>
            </ul>
            <h3> B. Assess Other Road Users :</h3>
            <ul>
              <li>
                Observe the behaviour of pedestrians, cyclists, and drivers.
              </li>
              <li>
                <strong>Ask yourself :</strong> <br />
                - What are they doing now? <br />- What might they do next?
              </li>
              <li>
                <strong>Examples :</strong> <br />
                - A parked car with brake lights on might pull out. <br />- A
                pedestrian standing near a crossing may step onto the road.
              </li>
            </ul>
            <h3>C. Respond to Road Signs and Markings : </h3>
            <ul>
              <li>
                Use road signs and markings to anticipate upcoming changes, such
                as bends, speed limits, or junctions
              </li>
              <li>
                <strong>Examples :</strong> <br />
                A "Give Way" sign indicates a need to check for traffic before
                proceeding. <br />A sharp bend sign allows you to adjust speed
                in advance.
              </li>
            </ul>
            <h3>C. Respond to Road Signs and Markings : </h3>
            <ul>
              <li>
                Anticipate how conditions such as rain, fog, or ice may affect
                road surfaces and other drivers.
              </li>
              <li>
                Plan extra stopping distances and adjust your speed accordingly.
              </li>
            </ul>
            <h3>E. Practice the "What If?" Mindset</h3>
            <ul>
              <li>
                <strong>Constantly ask yourself :</strong>
                <br></br>
                - What if that driver suddenly brakes? <br />
                - What if the cyclist changes direction? <br />- What if the
                lights change to red before I reach the junction? <br />- This
                mental rehearsal prepares you to act quickly and decisively.
              </li>
            </ul>
          </div>
        </section>

        <section className={styles.AdiModuleOneactivity}>
          <h2>Hazard Prediction Challenge </h2>
          <p>During your next practice drive, complete the following tasks :</p>
          <div className={styles.AdiModuleOnechecklist}>
            <h3>1. Identify Hazards Early :</h3>
            <ul>
              <li>
                As you drive, name at least five potential hazards (e.g., a
                pedestrian near a crossing or a vehicle waiting to turn).
              </li>
            </ul>
            <h3>2. Describe Your Plan :</h3>
            <ul>
              <li>
                For each hazard, say out loud or think about how you plan to
                react. For example:
              </li>
            </ul>
            <h3>Part C: Review and Feedback :</h3>
            <ul>
              <li>
                1. Ask a trainer, mentor, or trusted observer to review your
                driving and provide feedback on your anticipation
              </li>
              <li>
                2. Discuss areas where you excelled and where improvement is
                needed.{" "}
              </li>
            </ul>
          </div>
        </section>
      </div>
    </>
  );
}
