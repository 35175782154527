import React from "react";
import styles from "./AdiModuleFive.module.css";

export default function AdiModuleFive() {
  return (
    <>
      {" "}
      <div className={styles.AdiModuleOnecontainer}>
        <section className={styles.AdiModuleOneheader}>
          <h1>Concentration in the COAST Method for Advanced Driving</h1>
          <div className={styles.AdiModuleOnevideo}>
            <p> INSERT VIDEO - CONCENTRATION</p>
          </div>
        </section>

        <section className={styles.AdiModuleOneobjectives}>
          <h2>Objective:</h2>
          <p>By the end of this lesson, you will:</p>
          <ul>
            <li>
              {" "}
              1. Understand the importance of Concentration in advanced driving.{" "}
            </li>
            <li>
              {" "}
              2. Learn practical strategies to maintain focus while driving.
            </li>
            <li>
              3. Participate in an activity to assess and enhance your
              concentration skills on the road.
            </li>
          </ul>
        </section>

        <section className={styles.AdiModuleOneintroduction}>
          <h2>1. Introduction to Concentration in Driving</h2>
          <p>
            Concentration is the foundation of safe and effective driving. In
            the COAST method, it is the first and most critical element, as it
            ensures you are fully aware of your surroundings and prepared to
            act.
          </p>
          <p>
            Without concentration, observation, anticipation, space management,
            and time planning can all fall apart. This is especially important
            in advanced driving, where the examiner will assess your ability to
            remain attentive and make decisions with precision.
          </p>
        </section>

        <section className={styles.AdiModuleOnechecks}>
          <h2>2. Why is Concentration Crucial in Advanced Driving? </h2>

          <div className={styles.AdiModuleOnecheckList}>
            <ul>
              <li>
                <strong>1. Avoiding Distractions :</strong> External
                distractions (e.g., environment distractions, passengers) or
                internal distractions (e.g., stress, fatigue) can lead to poor
                decision-making.
              </li>
              <li>
                <strong>2. Enhancing Reaction Time :</strong> Focus allows you
                to spot hazards early and react promptly.
              </li>

              <li>
                <strong>3. Supporting Smooth Driving :</strong> Concentration
                helps you anticipate changes and avoid sudden, jerky manoeuvres.
              </li>
              <li>
                <strong>4. Demonstrating Professionalism :</strong> Maintaining
                focus shows the examiner you are a competent, responsible, and
                prepared driver.
              </li>
            </ul>
          </div>
        </section>
        <section className={styles.AdiModuleOneactivity}>
          <h2>3. Strategies to Maintain Concentration While Driving</h2>
          <div className={styles.AdiModuleOnechecklist}>
            <h3>A. Prepare Yourself Before Driving :</h3>
            <ul>
              <li>
                <strong>Rest and Hydration : </strong>Ensure you are well-rested
                and hydrated to maintain mental clarity.{" "}
              </li>
              <li>
                {" "}
                <strong>Stress Management : </strong>Take a few deep breaths or
                practice mindfulness to clear your mind before starting the
                drive.{" "}
              </li>
            </ul>
            <h3>B. Minimise Distractions Inside the Vehicle :</h3>
            <ul>
              <li>
                {" "}
                <strong>Technology :</strong> Put your phone on “Do Not Disturb”
                mode and avoid fiddling with devices while driving.{" "}
              </li>
              <li>
                <strong>Comfort :</strong> Adjust your seat, mirrors, and
                controls before setting off.{" "}
              </li>
            </ul>
            <h3>C. Develop Focused Driving Habits :</h3>
            <ul>
              <li>
                <strong>Mirror Checks :</strong> Make a habit of scanning your
                mirrors every 5–8 seconds.
              </li>
              <li>
                <strong>Active Observation : </strong>Constantly identify and
                prioritise potential hazards (e.g., road signs, pedestrians).
              </li>
            </ul>
            <h3>D. Break Long Drives into Segments :</h3>
            <ul>
              <li>
                <strong>Take Breaks :</strong> On long drives, stop every 2
                hours to refresh your focus.
              </li>
            </ul>
            <h3>D. Break Long Drives into Segments :</h3>
            <ul>
              <li>
                <strong>Take Breaks :</strong> On long drives, stop every 2
                hours to refresh your focus.
              </li>
            </ul>
          </div>
        </section>

        <section className={styles.AdiModuleOneactivity}>
          <h2>Part B: Concentration Exercise During Driving </h2>
          <div className={styles.AdiModuleOnechecklist}>
            <h3>1. Mirror-Scanning Drill :</h3>
            <ul>
              <li>
                During your next practice drive, set a timer for 5 minutes.
              </li>
              <li>
                {" "}
                Focus on checking your mirrors every 5–8 seconds while also
                observing hazards ahead and to the sides.{" "}
              </li>
            </ul>
            <h3>2. Hazard Identification :</h3>
            <ul>
              <li>
                {" "}
                During the same drive, actively name potential hazards you
                notice (e.g., pedestrians, vehicles at junctions, or changing
                traffic lights).
              </li>
              <li>
                Briefly describe how you plan to respond to these hazards (e.g.,
                slowing down, changing lanes).
              </li>
            </ul>
            <h3>3. Commentary drive :</h3>
            <ul>
              <li>
                Speak out everything that you can see which could be a hazard,
                and what your response is. i.e. ‘Up in front there is a zebra
                crossing, there is a pedestrian walking close they could cross
                therefore I am going to take my foot off the accelerator
                slightly to anticipate this, the pedestrian is going to cross so
                I am breaking and preparing to stop in good time at the stop
                line. There is a car stopping behind me so before pulling off I
                am going to check left and right for other pedestrians and also
                behind me’
              </li>
              <li>
                <strong>Active Observation : </strong>Constantly identify and
                prioritise potential hazards (e.g., road signs, pedestrians).
              </li>
            </ul>
            <h3>D. Break Long Drives into Segments :</h3>
            <ul>
              <li>
                Commentary drive sets you up for what you will be doing in part
                3 with your students.
              </li>
            </ul>
          </div>
        </section>
      </div>
    </>
  );
}
